





























































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Provide } from 'vue-property-decorator';
import OrganisationProfileView from '@/modules/organisation/pages/OrganisationProfileView.vue';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import { mapGetters, mapState } from 'vuex';
import PaymentsDto from '../../../app/entities/subscription/IPaymentDto';
import { ITenantDto } from '@/app/entities';
// import ApiService from '@/services/api/accommodation.service';
import { Ref } from 'vue-property-decorator';
// import service from '../../../services/app-http-client';
import OrganisationPaymentModal from '@/modules/organisation/components/Pricing.vue';
import UpgradeButton from '@/modules/marketing/pricing/UpgradeButton.vue';
import SubscribeButton from '@/modules/marketing/pricing/SubscribeButton.vue';
import { IBillingDto } from '../../../app/entities';
import { customPrint } from '@/app/utils/print';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import userModule from '@/store/modules/user/user.module';
import { Loading, Loader } from '@/modules/auth/store/auth.service';
import isEmpty from '@/app/utils/object.utils';
import Routes from '@/shared/constants/routes';
import { buildAbilityFor } from '@/plugins/casl/ability';
import dayjs from '@/app/utils/date';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  name: 'billing',
  components: {
    OrganisationProfileView,
    OrganisationPaymentModal,
    UpgradeButton,
    SubscribeButton,
  },
  layout: 'AppMain',
  computed: {
    ...mapState('tenant', ['payments', 'tenant']),
    ...mapGetters('tenant', ['billing']),
  },
})
export default class Billing extends mixins(SchemaMixin) {
  @Ref('bill') readonly bill: FormulateForm;
  @Provide() activeIndex = 0;

  payments!: PaymentsDto[];
  tenant: ITenantDto;
  billing!: IBillingDto;
  trial = tenantModule.tenant.subscription.plan.name === 'Trial';
  popupActive = false;
  userRole = userModule.member[0].role.role;
  loading = true;
  showPaymentModal = false;
  billingFormComplete = false;

  companyDetails = {
    name: 'Handy Cats Pty Ltd ',
    name2: 'T/A Check-in Buddy',
    addressLine1: 'Unit 4, 18 Rose Avenue',
    addressLine2: 'Kirstenhof 7945',
    addressLine3: 'Cape Town RSA',
    vatNo: '4630286336',
    mailId: 'accounts@handycats.com',
    mobile: '+27 87 057 9139',
  };

  recipientDetails = {
    organisationName: tenantModule.tenant.billing?.billingCompany,
    fullName: tenantModule.tenant.billing?.billingContact,
    billingAddress: tenantModule.tenant.billing?.billingAddress,
    vatNo: tenantModule.tenant.billing?.vatNo,
    mailId: tenantModule.tenant.billing?.billingEmail,
    mobile: tenantModule.tenant.billing?.billingPhone?.numberFormatted,
  };

  selectedPayment = {} as any;

  async created() {
    await tenantModule.getPayments(tenantModule.tenant.id);
    if (!isEmpty(tenantModule.tenant)) {
      if (Object.keys(tenantModule.tenant).length !== 0) {
        await tenantModule.getTenant();
        this.loading = false;
      }
    }
    this.loading = false;
  }

  mounted() {
    if (this.billing?.billingCompany && this.billing?.billingEmail && this.billing?.vatNo !== '') {
      this.billingFormComplete = true;
    }
  }

  billingDate() {
    if (!this.trial) {
      return tenantModule.tenant.subscription.currentPeriodEnd;
    } else {
      return 'none';
    }
  }

  viewInvoice(payment: any, ind: any): void {
    this.activeIndex = ind;
    this.selectedPayment = payment;
    this.popupActive = true;
  }

  print() {
    customPrint('invoice-page', {
      name: '_blank',
      specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
      styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
      ],
      timeout: 1000,
      autoClose: true,
      windowTitle: window.document.title,
    });
  }

  @Loading
  async handle(): Promise<any> {
    const validationCheck = this.bill.formSubmitted();
    if (validationCheck) {
      return this.updateTenant();
    }
    return validationCheck;
  }

  @Loader('Updating your account details')
  async updateTenant(): Promise<any> {
    const payload = this.tenant;
    payload.billing.billingPhone = this.getHashMap(this.tenant.billing.billingPhone);

    if (this.tenant.id) {
      await tenantModule.updateTenant(payload);
      this.tenant = tenantModule.tenant as ITenantDto;
      this.billingFormComplete = true;
      this.$swal('Updated!', 'Your account details have been updated.', 'success');
      return true;
    }
    await tenantModule.createTenant(payload);

    buildAbilityFor(userModule.member[0].role.ability as any);
    this.$ability.update(userModule.member[0].role.ability as any);

    return this.$router.push(Routes.ORGANISATION_DASHBOARD);
  }
}
